@media (max-width: 1024px) {
  $max-width: 1024 !global;

  @import "./common";
  @import "./components";

  .button-group {
    gap: 1.25rem;
  }

  .main {
    max-height: 42rem;

    .container {
      max-width: 60rem;
    }

    .title {
      gap: 2rem;
      padding-top: 10rem;

      .fido-group {
        gap: 2rem;
        transform: scale(0.8);
      }
    }

    .box-item {
      &:first-child {
        left: calc(50% - 244px);
        transform: translateX(-50%) scale(0.8);
        bottom: -14rem;
      }
      &:last-child {
        right: calc(50% - 244px);
        transform: translateX(50%) scale(0.8);
        bottom: -17rem;
      }
    }
  }

  .main-bottom {
    height: 16rem;
  }

  .try-for-free {
    .container {
      gap: 4rem;
    }
  }

  #Login,
  #Authenticators,
  #ChangePassword {
    padding: 10rem;

    .container {
      gap: 2rem;
    }

    .input-group {
      padding: 2.5rem;

      > a {
        font-weight: 700;
        text-align: center;
      }
    }
  }

  #Authenticators {
    .item-group {
      gap: 1.5rem;
    }
    .content {
      gap: 1.5rem;

      .default-group {
        gap: 1.5rem;
      }

      .list-group {
        gap: 1rem;

        .item-list {
          padding: 1.25rem;
          gap: 1.25rem;

          p {
            font-size: 1rem;
          }

          .thumb-item {
            width: 4rem;
            height: 4rem;
          }
        }

        .auth-list {
          padding: 2rem;
          .text-item {
            p {
              font-size: 1rem;
            }
          }
        }
      }

      .empty-list {
        gap: 1.5rem;
        padding: 1.5rem;

        p {
          font-size: 1rem;
        }
      }
    }
  }

  #StoreArea {
    bottom: 7rem;
    padding: 1rem 0;

    .container {
      gap: 2rem;

      .logo {
        height: 1.25rem;
      }
    }
  }
}
