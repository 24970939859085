.sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 10rem;
  padding: 1rem;
  background: $G10;
  border-radius: 0.5rem;

  ul {
    flex: 1;

    li.menu {
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      padding: 1rem 0.5rem;

      &:hover {
        background: $G20;
      }
      &.active {
        color: $primary;
      }
    }
  }
}

.components {
  display: flex;
  flex-direction: row;
  column-count: auto;
  gap: 2rem;
  max-width: 120rem;
  height: calc(100vh - 2rem);
  margin: 0 auto;
  padding: 1rem;

  .contents {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    padding: 1rem;
  }

  header {
    position: unset;
  }
}

.checkbox-item {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    display: none;
  }

  .checkbox {
    cursor: pointer;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.25rem;
    box-shadow: inset 0px 0px 0px 1px $G50;
    // background: red;

    i.icon {
      opacity: 0;
    }
  }

  input:checked + label .checkbox {
    background: $primary;
    box-shadow: unset;
    animation: Scale 0.3s ease;

    i.icon {
      opacity: 1;
    }
  }

  input:disabled + label .checkbox,
  input[disabled] + label .checkbox {
    button: disabled;
    cursor: auto;
    background: $G20;
    box-shadow: inset 0px 0px 0px 1px $G30;
  }
}

.dropdown-item {
  position: relative;
  display: flex;
  z-index: 1;

  label {
    display: unset;
    flex: 1;
  }
  .dropdown {
    cursor: pointer;
    overflow: hidden;

    .dropdown-select {
      position: relative;
      display: flex;
      align-items: center;
      column-count: auto;
      gap: 0.625rem;
      padding: 0.75rem 0;
      box-shadow: inset 0 -1px 0 $G30;

      p {
        flex: 1;
        font-size: 1rem;
        color: $G50;
      }
    }

    ul.dropdown-option {
      max-height: 200px; 
      overflow-y: auto;
      display: none;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      background: $G00;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      transform: translateY(-10%);
      transition: 0.3s;
      z-index: 2;


      li {
        padding: 0.75rem;
        font-size: 1rem;
        line-height: 1.5rem;

        &:hover {
          background: rgba($color: $G10, $alpha: 0.4);
        }

        &.active {
          color: $primary;
          font-weight: bold;
        }
      }
    }

    &.active {
      span.focus-border {
        width: 100%;
      }
      ul.dropdown-option {
        display: block;
        opacity: 1;
        transform: translateY(5%);
      }
    }
  }

  select {
    position: absolute;
    visibility: hidden;
    display: none;
  }
}

.accordion-item {
  padding: 0 0.75rem;
  box-shadow: inset 0 0 0 1px $G30;
  border-radius: 0.75rem;

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    box-shadow: inset 0 -1px 0 $G30;
  }

  ul {
    display: none;
    opacity: 0;

    li {
      p {
        padding: 12px 0;
        line-height: 1.2;
      }
    }
  }

  &.active {
    .accordion-header {
      button {
        img {
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }
    ul {
      display: block;
      opacity: 1;
      animation: FadeInDown 0.3s ease;
    }
  }
}

.tabs {
  display: flex;
  width: 100%;

  .button-group {
    gap: unset;
  }

  .tab-item {
    cursor: pointer;
    font-size: 1.125rem;
    color: $G40;
    line-height: 1.2;
    padding: 0.75rem;
    box-shadow: inset 0 -1px 0 $line-G40;
    flex: 1;
    text-align: center;

    &.active {
      font-weight: bold;
      color: $primary;
      box-shadow: inset 0 -2px 0 $primary;
    }
  }
}

.steps {
  display: flex;
  justify-content: center;
  width: auto;

  ul {
    display: flex;
    column-count: auto;
    gap: 3.375rem;

    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      border-radius: 50%;
      color: $secondary;
      box-shadow: inset 0 0 0 1px $secondary;

      &::after {
        content: "";
        position: absolute;
        left: 2.5rem;
        width: 3.375rem;
        height: 2px;
        background-image: url("../../images/svg/icon-dot.svg");
        background-size: 6px 2px;
        background-repeat: repeat-x;
        background-position: bottom;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.active {
        color: $G00;
        background: $primary;
        box-shadow: inset 0 0 0 1px $primary;
      }

      &.success {
        background: $secondary;
        box-shadow: inset 0 0 0 1px $secondary;

        &::after {
          background-size: 100%;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($color: $G100, $alpha: 0.7);
  backdrop-filter: blur(0.125rem);
  -webkit-backdrop-filter: blur(0.125rem);
  z-index: 9998;
  animation: OpacityAni 0.3s;
  display: none;

  button.gnb {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 30rem;

    button.gnb {
      z-index: 9;
    }

    .modal-dialog-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      column-count: auto;
      padding: 2.5rem 1.25rem;
      gap: 1.5rem;
      background: $G00;
      border-radius: 0.75rem;
    }

    span.line {
      display: block;
      background: $G30;
      width: 100%;
      height: 1px;
    }

    .modal-header {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      column-count: auto;
      gap: 1.5rem;

      p.user-email {
        flex: 1;
        text-align: center;
        font-size: 1rem;
        line-height: 1.2;
        color: $G80;
        background: $G20;
        padding: 1.25rem 2.5rem;
        border-radius: 0.625rem;
      }

      .img-item {
        display: flex;
        justify-content: center;

        img.qr-img {
          width: 9.125rem;
          height: 9.125rem;
        }
        img.step-img {
          width: 7.5rem;
          height: 7.5rem;
        }
      }

      .text-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-count: auto;
        gap: 0.75rem;

        p {
          max-width: 20rem;
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1.2;
          text-align: center;
          margin-bottom: 1rem;
        }

        span {
          max-width: 20rem;
          font-size: 1.1rem;
          line-height: 1.2;
          text-align: center;
        }
      }

      .input-group {
        width: 100%;
        max-width: 22.5rem;
        margin: 0 auto;
      }

      .qr-img {
        padding: 0.5rem;
        animation: OpacityAni 0.3s;

        img {
          width: 15rem;
        }
      }
    }

    .modal-footer {
      display: flex;

      .button-group {
        a {
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}
