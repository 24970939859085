* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: $G100;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "SUIT", -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}
h1 {
  font-size: 4.5rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.5rem;
}
ul,
li {
  list-style: none;
}
a {
  color: $primary;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 1.2;
  border: none;
  background: $G00;
  font-family: "SUIT-Regular", -apple-system, BlinkMacSystemFont, system-ui,
    sans-serif !important;

  &.btn-md {
    padding: 1rem 0;
    border-radius: 0.75rem;
    font-size: 1rem;
  }
  &.btn-lg {
    padding: 1.5rem 0;
    border-radius: 0.625rem;
    font-size: 1.125rem;
  }
  &.btn-primary-line {
    box-shadow: inset 0 0 0 1px $primary;
    color: $primary;
  }
  &.btn-primary-fill {
    box-shadow: inset 0 0 0 1px $primary;
    color: $G00;
    background: $primary;
  }
  &.btn-none-line {
    font-size: 1rem;
    color: $G100;
  }
  &.btn-header {
    height: 2.25rem;
    background: unset;
  }
  &.btn-icon {
    column-count: auto;
    align-items: center;
    font-size: 1rem;
    gap: 0.25rem;
  }

  &.arrow {
    background: unset;
    object-fit: cover;
    padding: 0;
    margin: 0;

    &:hover {
      background: rgba($color: $G10, $alpha: 0.4);
    }

    &.size-24 {
      width: 1.5rem;
      height: 1.5rem;
    }
    &.size-16 {
      width: 1rem;
      height: 1rem;
    }
  }

  &.gnb {
    background: none;
    object-fit: cover;
    padding: 0;
    margin: 0;

    &:hover {
      background: rgba($color: $G10, $alpha: 0.4);
    }
    img {
      width: 2rem;
      height: 2rem;
    }
  }

  &:disabled,
  &[disabled] {
    button: disabled;
    cursor: auto;
    box-shadow: unset;
    color: $G00;
    background: $G40;
    box-shadow: inset 0 0 0 1px $G50 !important;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  column-count: auto;
  gap: 1rem;

  h5 {
    text-align: center;
  }

  .input-item {
    display: flex;
    flex-direction: column;
    column-count: auto;
    gap: 0.5rem;

    .input {
      position: relative;
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: 0.5rem;

      .timer {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 1.125rem;
        line-height: 1.5;
      }
    }

    &.error {
      span.focus-border {
        width: 100%;
        background: $error;
      }
      span.error-message {
        display: block;
      }
    }
  }
  a.forget {
    margin-top: 0.25rem;
    text-align: right;
  }

  .divider {
    display: flex;
    align-items: center;
    column-count: auto;
    gap: 1.5rem;

    span.line {
      display: flex;
      flex: 1;
      height: 1px;
      background: #d9d9d9;
    }

    p {
      flex: 1;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}

input,
textarea {
  font-size: 1.125rem;
  padding: 0.75rem 0;
  border: 0;
  border-bottom: 1px solid $G30;
  color: $G80;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $G40;
  }
  &:disabled,
  &[disabled] {
    button: disabled;
    cursor: auto;
    background: $G10;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input:focus ~ span.focus-border {
  width: 100%;
}

span.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: $primary;
  transition: 0.4s;
}
span.error-message {
  display: none;
  font-size: 0.875rem;
  line-height: 1.2;
  color: $error;
}

label {
  display: flex;
  align-items: center;
  column-count: auto;
  gap: 0.5rem;
  font-size: 1rem;
  color: $G70;

  &.view-title {
    font-size: 1rem;
    font-weight: bold;
  }
  &.bold {
    font-size: 1.5rem;
  }
}

section {
  display: none;
}

header {
  background: rgba($color: $G00, $alpha: 0.2);
  box-shadow: inset 0 -1px 0 $line-G40;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 0;
  }

  .header-left {
    display: flex;
    align-items: center;

    .logo {
      cursor: pointer;
      height: 1.5rem;
    }

    h5 {
      font-size: 1rem;
      color: $G00;
    }
  }
  .header-center {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
      column-count: auto;
      gap: 3.75rem;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 2rem;
        font-size: 1.125rem;
        line-height: 1.2;

        &.active {
          color: $primary;
          font-weight: 700;
        }
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    column-count: auto;
    gap: 1.25rem;
  }
  .user-info {
    display: flex;
    align-items: center;
    column-count: auto;
    gap: 0.25rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background: $G10;
    border-radius: 5rem;

    span {
      color: $G100;
      font-weight: 700;
    }
  }
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: $G70;
  color: $G00;

  &.pc {
    .footer-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      max-width: 80rem;
      margin: 0 auto;
      padding: 2rem 0;
    }

    .footer-left {
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: 0.75rem;

      .brand-title {
        font-size: 1rem;
        font-weight: 700;
      }

      .text-item {
        display: flex;
        flex-direction: column;
        column-count: auto;
        gap: 0.25rem;

        span {
          display: block;
          font-size: 0.875rem;
        }
      }
    }

    .footer-right {
      ul {
        display: flex;
        column-count: auto;
        align-items: center;

        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 700;

          &::after {
            content: "";
            width: 1px;
            height: 0.875rem;
            background: $G50;
            margin: 0 0.5rem;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}

.pc-only {
  display: block;
}
.mo-only {
  display: none;
}
.flex {
  display: flex;
  column-count: auto;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.w-10rem {
  width: 10rem;
}
.w-21rem {
  width: 21.25rem;
}
.bold {
  font-weight: 700;
}
.show {
  display: flex;
  flex-direction: column;
  column-count: auto;
  gap: 1rem;
  opacity: 1;
}
.open {
  display: block !important;
}
.open-flex {
  display: flex !important;
}
// 컬러 스타일
.color-primary {
  color: $primary;
}
.color-error {
  color: $error;
}
.color-black {
  color: $G100;
}
.required {
  color: $error;
}
// 텍스트 스타일
.text-unerline {
  text-decoration: underline;
}
