h1 {
  font-size: vw(32 * 2);
}

h4 {
  font-size: vw(20 * 2);
}

h5 {
  font-size: vw(14 * 2);
}

a {
  color: $primary;
}

button {
  gap: vw(4 * 2);

  &.btn-md {
    font-size: vw(16 * 2);
    padding: vw(16 * 2) 0;
    border-radius: vw(12 * 2);
  }
  &.btn-lg {
    font-size: vw(16 * 2);
    padding: vw(24 * 2) 0;
    border-radius: vw(12 * 2);
  }
  &.btn-primary-line {
    box-shadow: inset 0 0 0 vw(1 * 2) $primary;
  }
  &.btn-primary-fill {
    box-shadow: inset 0 0 0 vw(1 * 2) $primary;
  }
  &.btn-none-line {
    font-size: vw(16 * 2);
    padding: 0;
  }
  &.btn-header {
    font-size: vw(12 * 2);
    line-height: 1.5;
    height: unset;
    box-shadow: inset 0 0 0 1px $brand;
    padding: vw(8 * 2) vw(12 * 2);
    border-radius: vw(8 * 2);
    color: $brand;
  }
  &.gnb {
    img {
      width: vw(34 * 2);
      height: vw(34 * 2);
    }
  }

  i {
    img {
      width: vw(16 * 2);
    }
  }
}

header {
  .header-container {
    max-width: unset;
    padding: vw(16 * 2);
  }

  .header-left {
    .logo {
      height: vw(24 * 2);
    }
  }

  .header-right {
    gap: vw(8 * 2);
  }
}

footer {
  &.mo {
    .footer-container {
      display: flex;
      flex-direction: column;
      column-count: auto;
      padding: vw(20 * 2);
      gap: vw(20 * 2);
    }

    .brand-title {
      font-size: vw(18 * 2);
      font-weight: 700;
    }

    .text-item {
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: vw(12 * 2);

      span {
        font-size: vw(14 * 2);

        &:last-child {
          color: $G50;
          line-height: 1;
        }
      }
    }

    ul {
      display: flex;
      column-count: auto;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: vw(12 * 2);
        font-weight: 700;
        letter-spacing: -0.2px;

        &::after {
          content: "";
          width: vw(1 * 2);
          height: vw(14 * 2);
          background: $G50;
          margin: 0 vw(8 * 2);
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

.input-group {
  gap: vw(24 * 2);

  .input-item {
    gap: vw(8 * 2);
  }

  a.forget {
    font-size: vw(14 * 2);
  }

  .divider {
    gap: vw(24 * 2);
    p {
      text-align: center;
      font-size: vw(14 * 2);
    }
  }
}

input,
textarea {
  font-size: vw(18 * 2);
  padding: vw(12 * 2) 0;
}

label {
  font-size: vw(16 * 2);
}

span.error-message {
  font-size: vw(14 * 2);
}

.pc-only {
  display: none;
}
.mo-only {
  display: block;
}
