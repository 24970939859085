$primary: #4974d1;
$secondary: #9cafd8;
$brand: #ff8400;

$error: #ff4d4d;

$G00: #ffffff;
$G10: #f5f5f7;
$G20: #efefef;
$G30: #dedede;
$G40: #bfbfbf;
$G50: #909090;
$G60: #666666;
$G70: #444444;
$G80: #333333;
$G90: #1a1a1a;
$G100: #000000;

$line-G20: #efefef;
$line-G30: #e5e5e5;
$line-G40: #d9d9d9;

$mobile-size: 0;
