header {
  .header-container {
    padding: 2rem 1rem;
  }
}

footer {
  &.pc {
    .footer-container {
      padding: 2rem 1rem;
    }
  }
}
