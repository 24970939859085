#root {
  position: relative;
  height: auto;
  min-height: 100%;
  padding-bottom: 8.5rem;
}

@media (max-width: 1024px) {
  #root {
    padding-bottom: 7rem;
  }
}