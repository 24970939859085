.components {
  position: relative;
  flex-direction: column;
  max-width: unset;
  padding: unset;
  width: 100%;
  height: unset;
  min-height: 100vh;

  .contents {
    gap: vw(16);
    padding: vw(16);
  }

  .components-menu {
    padding: vw(16);
  }
}

.dropdown-item {
  .dropdown {
    .dropdown-select {
      gap: vw(10);
      padding: vw(12) 0;

      p {
        font-size: vw(16);
      }

      i {
        img {
          width: vw(20);
        }
      }
    }

    ul.dropdown-option {
      li {
        padding: vw(12) ;
        font-size: vw(16);
        line-height: unset;
      }
    }
  }
}

.tabs {
  .tab-item {
    font-size: vw(18);
    padding: vw(12) 0;
  }
}

.steps {
  ul {
    gap: vw(24);

    li {
      width: vw(40);
      height: vw(40);
      font-size: vw(16);

      &::after {
        left: vw(40);
        width: vw(54);
        height: vw(2);
        background-size: vw(6) vw(2);
      }

      &.active {
        box-shadow: inset 0 0 0 vw(1) $primary;
      }

      &.success {
        box-shadow: inset 0 0 0 vw(1) $secondary;

        &::after {
          background-size: 100%;
        }

        i {
          width: vw(24);
          height: vw(24);
        }
      }
    }
  }
}

.modal {
  button.gnb {
    position: absolute;
    top: 0;
    right: 0;
    width: vw(28);
  }

  .modal-dialog {
    min-width: vw(320);
    padding-top: vw(32) + vw(12);

    .modal-dialog-inner {
      gap: vw(24);
      padding: vw(40) vw(20);
      border-radius: vw(12);
    }

    .modal-header {
      text-align: center;
      h5 {
        font-size: vw(20);
        line-height: 1.5;
      }
    }

    .modal-body {
      gap: vw(24);

      P.open-the-app {
        font-size: vw(14);
        font-weight: 700;
        text-decoration: underline;
        color: $G80;
      }
      p.user-email {
        font-size: vw(16);
        line-height: 1.2;
        padding: vw(20) vw(40);
        border-radius: vw(12);
      }

      .img-item {
        img.qr-img {
          width: vw(63);
          height: vw(63);
        }
        img.step-img {
          width: vw(80);
          height: vw(80);
        }
      }

      .qr-img {
        padding: 1rem;

        img {
          width: vw(120);
        }
      }

      .text-group {
        gap: vw(12);

        p {
          max-width: unset;
          font-size: vw(16);
        }

        span {
          max-width: unset;
          font-size: vw(14);
        }
      }

      span.timer {
        font-size: vw(16);
        font-weight: 700;
        line-height: 1.5;
      }

      .input-group {
        max-width: unset;
      }
    }

    .modal-footer {
      .button-group {
        gap: vw(24);

        a {
          font-size: vw(16);
        }
      }
    }
  }
}
