@media (max-width: 768px) {
  $mobile-size: 768 !global;
  @import "./common";
  @import "./components";

  #root {
    padding-bottom: vw(178.5 * 2);
  }

  .main {
    height: vw(468 * 2);
    max-height: unset;
    padding-top: vw(68 * 2);

    .container {
      max-width: unset;
      padding: 0 vw(20 * 2);
    }

    .title {
      padding-top: vw(60 * 2);
      gap: vw(40 * 2);

      h1 {
        padding-left: vw(14 * 2);
        padding-right: vw(14 * 2);
      }

      .fido-group {
        transform: unset;

        img {
          width: vw(82 * 2);
          height: vw(40 * 2);
        }
      }
    }

    .content {
      width: 100%;
    }

    .box-item {
      width: 100%;
      max-width: unset;
      max-height: unset;
      margin-top: vw(20 * 2);
      padding: vw(32 * 2);

      &:first-child,
      &:last-child {
        position: unset;
        transform: unset;
      }

      &:first-child {
        .img-group {
          margin-top: vw(36 * 2);
          img {
            width: vw(100 * 2);
            height: vw(74 * 2);
          }
        }
      }

      &:last-child {
        .img-group {
          margin-top: vw(10 * 2);
          img {
            width: vw(74 * 2);
            height: vw(100 * 2);
          }
        }
      }

      .text-group {
        gap: vw(12 * 2);
        margin-bottom: vw(24 * 2);

        p {
          font-size: vw(20 * 2);
        }

        span {
          font-size: vw(14 * 2);
        }
      }

      button {
        max-width: vw(165 * 2);
      }
    }
  }

  .main-bottom {
    height: vw(708 * 2);
  }

  .try-for-free {
    padding: vw(60 * 2) vw(20 * 2);

    .container {
      gap: vw(24 * 2);
    }

    .title {
      gap: vw(12 * 2);
    }

    .sub-title {
      gap: 0.75rem;

      p {
        display: none;
      }

      span {
        max-width: vw(272 * 2);
        font-size: vw(14 * 2);
        color: $G60;
      }
    }

    .input-group {
      max-width: unset;
      gap: vw(32 * 2);

      p.info-box {
        font-size: vw(16 * 2);
      }
    }

    .button-group {
      button {
        min-width: vw(180 * 2);
      }
    }
  }

  #Login,
  #ChangePassword {
    padding: vw(68 * 2) vw(20 * 2);
    margin-bottom: vw(139.5 * 2);

    .container {
      padding: vw(60 * 2) 0;
      gap: vw(60 * 2);
    }

    .title {
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: vw(12 * 2);
      text-align: center;

      h5 {
        font-size: vw(14 * 2);
        color: $G60;
      }
    }

    .input-group {
      max-width: unset;
      padding: 0;
      box-shadow: unset;
      gap: vw(32 * 2);

      h5 {
        font-size: vw(14 * 2);
        font-weight: 400;
        color: $G60;
      }

      > a {
        font-weight: 400;
        font-size: vw(14 * 2);
      }
    }

    .button-group {
      margin-top: vw(8 * 2);
      gap: vw(16 * 2);

      p {
        font-size: vw(16 * 2);
        gap: vw(4 * 2);
      }
    }
  }

  #ChangePassword {
    margin-bottom: 0;

    .container {
      gap: vw(12 * 2);
    }

    .button-group {
      margin: 0;
    }
  }

  #Documentation {
    padding: vw(68 * 2) vw(20 * 2);
    margin-bottom: unset;

    .container {
      gap: vw(60 * 2);
      padding-top: vw(60 * 2);
    }

    .container-inner {
      display: flex;
      flex-direction: column;
      gap: vw(32 * 2);
    }
    .document-menu {
      display: none;
    }

    .document-content {
      padding-top: unset;

      .content-item {
        margin-top: 2rem;
        gap: vw(32 * 2);

        h5 {
          font-size: vw(18 * 2);
        }

        p {
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 2.0;
          padding-bottom : 1.1rem;
          
        }

        .context {
          margin-bottom: 2rem;
          gap: vw(24 * 2);

          h5,
          p {
            font-size: vw(16 * 2);
          }

          h6 {
            color: $primary;
            font-size: vw(16 * 2);
            font-weight: 400;
          }

          ul {
            display: block;
            list-style-type: disc;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 30px;
          }
          
          ol {
            display: block;
            list-style-type: decimal;
            margin-top: 10px;
            padding-inline-start: 20px;
          }
          
          li {
            display: list-item;
            list-style: circle;
            margin-top: 10px;
          } 

          p {
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 2.0;
            color: $G60;
            padding-bottom : 1.1rem;
            
          }
  
          div.figure{
            text-align: center;
            font-weight: bold;
            margin-top: 0px;
          }

          .code-block {
            code {
              span {
                font-size: vw(14 * 2);
              }
            }
          }

          .doc-table {
            border: 1px solid #e8e8e8; /* 테두리 스타일 설정 */
          }
        }
      }
    }
  }

  #Authenticators {
    padding: vw(68 * 2) 0;
    margin-bottom: vw(139.5 * 2);

    > .container {
      padding: vw(60 * 2) 0;
      gap: vw(60 * 2);
    }

    .item-group {
      max-width: unset;
      gap: vw(32 * 2);

      .tabs {
        .button-group {
          gap: unset;
          padding: 0;
        }
      }
    }

    .content {
      padding: 0 vw(20 * 2);
      gap: vw(40 * 2);

      .list-group {
        gap: vw(12 * 2);

        .item-list {
          gap: vw(24 * 2);
          padding: vw(20 * 2);
          border-radius: vw(24 * 2);
          border: vw(1 * 2) solid $line-G40;

          p {
            font-size: vw(18 * 2);
          }
          .thumb-item {
            width: vw(60 * 2);
            height: vw(60 * 2);
          }
          .icon-plus,
          .icon-delete {
            width: vw(32 * 2);
            height: vw(32 * 2);
          }
        }
        .auth-list {
          gap: vw(24 * 2);
          padding: vw(32 * 2);
          border-radius: vw(20 * 2);

          .thumb-item {
            width: vw(64 * 2);
            height: vw(64 * 2);
          }

          .text-item {
            gap: vw(4 * 2);

            p {
              font-size: vw(16 * 2);

              &:first-child {
                font-size: vw(18 * 2);
                padding-bottom: vw(8 * 2);
              }
            }
          }
        }
      }

      .empty-list {
        gap: vw(16 * 2);
        padding: vw(32 * 2);
        border-radius: vw(20 * 2);
        margin: unset;
        margin-bottom: vw(20 * 2);

        .warning-icon {
          width: vw(64 * 2);
          height: vw(64 * 2);
        }

        p {
          font-size: vw(18 * 2);
        }
      }
    }

  }

  #StoreArea {
    bottom: vw(178.5 * 2);
    padding: vw(24 * 2) vw(20 * 2);

    .container {
      flex-direction: column;
      max-width: unset;
      gap: vw(16 * 2);

      .logo {
        height: vw(24 * 2);
      }

      .button-group {
        width: 100%;
        gap: vw(12 * 2);
        padding: 0;
      }
    }
  }

  #SideMenu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color: $G100, $alpha: 0.7);
    backdrop-filter: blur(0.125rem);
    -webkit-backdrop-filter: blur(0.125rem);
    z-index: 9999;
    display: none;

    &.open {
      display: block;
    }

    .container {
      position: absolute;
      right: 0;
      width: calc(100% - 15%);
      height: 100%;
      padding: vw(32 * 2) vw(24 * 2);
      background: $G00;
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: vw(40 * 2);
      animation: SideLeft 0.3s;
    }

    span.line {
      display: block;
      background: $G30;
      width: 100%;
      height: 1px;
    }

    .header {
      position: relative;
      display: flex;

      .logo {
        height: vw(24 * 2);
      }

      button.gnb-close {
        position: absolute;
        top: 0;
        right: 0;

        img {
          width: vw(24 * 2);
          height: vw(24 * 2);
        }
      }
    }

    .body {
      .information {
        display: flex;
        flex-direction: column;
        align-items: center;
        column-count: auto;
        gap: vw(20 * 2);

        .user-info {
          padding: vw(8 * 2) vw(24 * 2);
          background: $G10;
          border-radius: vw(60 * 2);
          width: fit-content;
          gap: vw(4 * 2);

          i {
            img {
              width: vw(32 * 2);
              height: vw(32 * 2);
            }
          }
          span {
            display: block;
            font-size: vw(20 * 2);
            line-height: 1.5;
          }
        }

        .button-group {
          gap: vw(8 * 2);
          width: 100%;
        }

        .btn-icon {
          font-size: vw(18 * 2);
          i {
            img {
              width: vw(24 * 2);
              height: vw(24 * 2);
            }
          }
        }
      }
    }

    ul.menu {
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: vw(40 * 2);

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size: vw(18 * 2);
        font-weight: bold;
        line-height: 1.5;
        list-style: none;

        &.active {
          color: $primary;
          font-weight: 700;
        }

        img {
          width: vw(20 * 2);
        }
      }
    }

    #DepthMenu {
      animation: OpacityAni 0.3s;
      ul.depth1-menu {
        padding: 0;

        li {
          color: $G60;

          p {
            font-size: vw(16 * 2);
            font-weight: bold;
          }

          &.active {
            color: $primary;
          }
        }
      }
    }
  }

  #DialogPasskeySign {
    .steps {
      ul {
        gap: vw(24 * 2);

        li {
          &::after {
            width: vw(24 * 2);
          }
        }
      }
    }
  }

  #DialogFidoSign {
    .button-group {
      width: 100%;
    }
  }

  #DialogAddAuth {
    .modal-body {
      .text-group {
        p {
          max-width: vw(220 * 2);
        }
      }
      .input-group {
        max-width: unset;
      }
    }

    .modal-footer {
      .button-group {
        padding: 0;
      }
    }
  }

  #DialogForgetPassword,
  #DialogChangePassword {
    .modal-dialog {
      .modal-body {
        .text-group {
          p {
            max-width: vw(220 * 2);
            font-weight: 400;
          }
        }

        .input-group{
          input[readOnly] {
            background-color: #f2f2f2;
            color: #888;
          }
        }
      }
    }
  }

  #DialogTerms {
    iframe {
      height: 80vh;
    }
  }
}
